import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as api from "../../api/UserApi";

export const getUsersData = createAsyncThunk(
  "leads/getUsersData",
  async ({
    itemPerPage,
    currentPage,
    searchText,
    kycFilter,
    userStatus,
    kycStatus,
  }) => {
    console.log("here is fine");
    const response = await api.getAllUsers({
      itemPerPage,
      currentPage,
      searchText,
      kycFilter,
      userStatus,
      kycStatus,
    });
    console.log("response user",response);
    const user = response.data.data;
    return user;
  }
);

export const updateKycStatus = createAsyncThunk(
  "leads/updateKycStatus",
  async (body) => {
    const response = await api.updateKycStatus(body);
    response.data._id = body.userId;
    response.data.status = body.status;
    return response.data;
  }
);

export const updateUserStatus = createAsyncThunk(
  "leads/updateUserStatus",
  async (body) => {
    const response = await api.updateUserStatus(body);
    return response;
  }
);

export const leadsSlice = createSlice({
  name: "leads",
  initialState: {
    isLoading: false,
    totalpages: 0,
    leads: [],
  },
  reducers: {
    addNewLead: (state, action) => {
      let { newLeadObj } = action.payload;
      state.leads = [...state.leads, newLeadObj];
    },
    editLead: (state, action) => {
      const { index, updatedLead } = action.payload;
      state.leads[index] = updatedLead;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUsersData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getUsersData.fulfilled, (state, action) => {
        state.leads = action.payload.users;
        state.totalpages = action.payload.totalPages;
        state.isLoading = false;
      })
      .addCase(getUsersData.rejected, (state) => {
        state.isLoading = false;
        state.leads = [];
      })
      .addCase(updateKycStatus.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateKycStatus.fulfilled, (state, action) => {
        const updatedUser = action.payload;

        const index = state.leads.findIndex(
          (user) => user._id === updatedUser._id
        );
        if (index !== -1) {
          state.leads[index].isKycVerified = updatedUser.status;
        }
        state.isLoading = false;
      })
      .addCase(updateKycStatus.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(updateUserStatus.fulfilled, (state, action) => {
        const updatedUser = action.payload.data.data;
        const index = state.leads.findIndex(
          (user) => user._id === updatedUser._id
        );
        if (index !== -1) {
          state.leads[index].status = updatedUser.status;
        }
        state.isLoading = false;
      });
  },
});

export const { addNewLead, editLead } = leadsSlice.actions;

export default leadsSlice.reducer;
