import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as api from "../../api/PaymentApi";

export const addNewBank = createAsyncThunk(
  "bank/addNewBank", 
  async (formData) => {
    console.log("data in bank slice", formData);
    const response = await api.addPaymentData(formData);
    return response.data;
  }
);
export const getBankData = createAsyncThunk(
  "/banks/content",
  async ({ searchText }) => {
    const response = await api.getAllPayments({ searchText });
    console.log("bank slice", response);
    return response.data;
  }
);

export const editBankDetail = createAsyncThunk(
  "bank/editBankDetail",
  async (body) => {
    console.log("bankData", body);
    const response = await api.updatePayment(body);
    console.log("respose update bank", response.data);
    return response.data;
  }
);

export const deleteBank = createAsyncThunk(
  "/bank/content",
  async (_id) => {
    console.log("bank iddd", _id);
    const response = await api.deletePayment(_id);
    response._id=_id
    return response;
  }
);

export const bankSlice = createSlice({
  name: "bank",
  initialState: {
    isLoading: false,
    banks: [],
  },
  reducers: {
    addNewbank: (state, action) => {
      let { newLeadObj } = action.payload;
      state.banks = [...state.banks, newLeadObj];
    },

    // deleteLead: (state, action) => {
    //     const { index } = action.payload;
    //     state.banks.splice(index, 1);
    //   },
    editLead: (state, action) => {
      const { index, updatedLead } = action.payload;
      state.banks[index] = updatedLead;
    },
    updateLead: (state, action) => {
      const { index, data } = action.payload;
      state.banks[index] = { ...state.banks[index], ...data };
    },
  },

  extraReducers: {
    [getBankData.pending]: (state) => {
      state.isLoading = true;
    },
    [getBankData.fulfilled]: (state, action) => {
      console.log("actio payload is", action.payload);
      state.banks = action.payload;
      state.isLoading = false;
    },
    [getBankData.rejected]: (state) => {
      state.isLoading = false;
    },
    [addNewBank.pending]: (state) => {
      state.isLoading = true;
    },
    [addNewBank.fulfilled]: (state, action) => {
      console.log("bank new data fullfiled", action.payload.data);
      state.banks.push(action.payload.data);
      // window.location.href = '/app/charts'
      state.isLoading = false;
    },
    [addNewBank.rejected]: (state) => {
      console.log("is delete rejected");
      state.isLoading = false;
    },
    [deleteBank.pending]: (state) => {
      console.log("is delete pendinng");
      state.isLoading = true;
    },
    [deleteBank.fulfilled]: (state, action) => {
      console.log("deleted _id in fullfilled", action.payload._id);
      state.banks = state.banks.filter(bank => bank._id !== action.payload._id);
      // window.location.href = '/app/charts'
      // state.leads = action.payload
      state.isLoading = false;
    },
    [deleteBank.rejected]: (state) => {
      console.log("is delete rejected");
      state.isLoading = false;
    },
    [editBankDetail.pending]: (state) => {
      state.isLoading = true;
    },
    [editBankDetail.fulfilled]: (state, action) => {
      const updatedbank = action.payload;
      state.banks = state.banks.map((bank) =>
        bank._id === updatedbank._id ? updatedbank : bank
      );
      // window.location.href = '/app/charts'
      // state.leads = action.payload
      state.isLoading = false;
    },
    
    [editBankDetail.rejected]: (state) => {
      state.isLoading = false;
    },
  },
});

export const { addNewbank, deleteLead, editLead, updateLead } =
  bankSlice.actions;

export default bankSlice.reducer;
