import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'



export const getEventContent = createAsyncThunk('/eventss/contents', async () => {
    const response = await axios.get('/event/read')
    console.log("resposneevent",response);
    return response.data;
})

export const addEventDevices = createAsyncThunk("/events/content", async (obj) => {
    // console.log("callling");
    const response = await axios.post('/event/create', obj)
    console.log("responseaddnewevent", response.data);
    return response.data;
})

export const updateEvent = createAsyncThunk("/events/content", async (obj) => {
    console.log("obg",obj);
    const response = await axios.put(`/event/update/${obj.data._id}`, obj.data);
    console.log("responsedsadsa",response);
    return response.data;
})
export const deletedEvent = createAsyncThunk("/events/content", async (_id) => {
    // console.log("_iddsadasdas",_id);
    const response = await axios.delete(`/event/remove/${_id}`)
    // console.log("deletetee",response)
    return response.data;
})

export const eventSlice = createSlice({
    name: 'events',
    initialState: {
        isLoading: false,
        events: []
    },
    reducers: {
        addNewDevice: (state, action) => {
            let { newLeadObj } = action.payload
            state.events = [...state.events, newLeadObj]
        },

        // deleteLead: (state, action) => {
        //     let { index } = action.payload
        //     state.leads.splice(index, 1)
        // },
        editDevice: (state, action) => {
            const { index, updatedLead } = action.payload;
            state.events[index] = updatedLead;
        },
        // updateLead: (state, action) => {
        //     const { index, data } = action.payload;
        //     state.leads[index] = { ...state.leads[index], ...data };
        // },

    },

    extraReducers: {
        [getEventContent.pending]: state => {
            state.isLoading = true
        },
        [getEventContent.fulfilled]: (state, action) => {
            state.events = action.payload
            state.isLoading = false
        },
        [getEventContent.rejected]: state => {
            state.isLoading = false
        },
        [addEventDevices.pending]: state => {
            state.isLoading = true
        },
        [addEventDevices.fulfilled]: (state, action) => {
            state.events = action.payload
            state.isLoading = false
        },
        [addEventDevices.rejected]: state => {
            state.isLoading = false
        },
        [updateEvent.pending]: state => {
            state.isLoading = true
        },
        [updateEvent.fulfilled]: (state, action) => {
            window.location.href = '/app/settings-billing'
            // state.events = action.payload
            state.isLoading = false
        },
        [updateEvent.rejected]: state => {
            state.isLoading = false
        },
        [deletedEvent.pending]: state => {
            state.isLoading = true
        },
        [deletedEvent.fulfilled]: (state, action) => {
            window.location.href = '/app/settings-billing'
            // state.leads = action.payload
            state.isLoading = false
        },
        [deletedEvent.rejected]: state => {
            state.isLoading = false
        },
    }
})

export const { addNewEvent, deleteEvent, editDevice } = eventSlice.actions

export default eventSlice.reducer