import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import * as api from "../../api/OrderApi"



export const getProductContent = createAsyncThunk('/products/contents', async ({itemPerPage,currentPage,searchText, orderStatus, orderType }) => {
    const response = await api.getAllOrders({itemPerPage,currentPage,searchText, orderStatus, orderType })
    console.log("resposnedevice",response.data);
    return response.data//.data;
})

export const addNewProducts = createAsyncThunk("/products/content", async (obj) => {
    // console.log("callling");
    const response = await axios.post('/product/create', obj)
    console.log("responsenewadd create", response.data);
    return response.data;
})

export const updateProduct = createAsyncThunk("/products/content", async (obj) => {
    console.log("obg",obj);
    const response = await axios.put(`/product/update/${obj.data._id}`, obj.data)
    return response.data;
})
export const deletedProduct = createAsyncThunk("/products/content", async (_id) => {
    // console.log("_iddsadasdas",_id);
    const response = await axios.delete(`/product/remove/${_id}`)
    // console.log("deletetee",response)
    return response.data;
})

export const CalenderSlice = createSlice({
    name: 'product',
    initialState: {
        isLoading: false,
        totalPage:0,
        products: []
    },
    reducers: {
        addNewProduct: (state, action) => {
            let { newLeadObj } = action.payload
            state.products = [...state.products, newLeadObj]
        },

        // deleteLead: (state, action) => {
        //     let { index } = action.payload
        //     state.leads.splice(index, 1)
        // },
        editDevice: (state, action) => {
            const { index, updatedLead } = action.payload;
            state.products[index] = updatedLead;
        },
        // updateLead: (state, action) => {
        //     const { index, data } = action.payload;
        //     state.leads[index] = { ...state.leads[index], ...data };
        // },

    },

    extraReducers: {
        [getProductContent.pending]: state => {
            state.isLoading = true
        },
        [getProductContent.fulfilled]: (state, action) => {
            console.log(`inside the ordersdata = ${action.payload.totalPages}`);
            state.totalPage = action.payload.totalPages;
            state.products = action.payload.data;
            state.isLoading = false
        },
        [getProductContent.rejected]: state => {
            console.log(`inside the ordersdata = `);
            state.isLoading = false
        },
        [addNewProducts.pending]: state => {
            state.isLoading = true
        },
        [addNewProducts.fulfilled]: (state, action) => {
            state.products = action.payload
            state.isLoading = false
        },
        [addNewProducts.rejected]: state => {
            state.isLoading = false
        },
        [updateProduct.pending]: state => {
            state.isLoading = true
        },
        [updateProduct.fulfilled]: (state, action) => {
            window.location.href = '/app/product'
            // state.leads = action.payload
            state.isLoading = false
        },
        [updateProduct.rejected]: state => {
            state.isLoading = false
        },
        [deletedProduct.pending]: state => {
            state.isLoading = true
        },
        [deletedProduct.fulfilled]: (state, action) => {
            window.location.href = '/app/product'
            // state.leads = action.payload
            state.isLoading = false
        },
        [deletedProduct.rejected]: state => {
            state.isLoading = false
        },

    }
})

export const { addNewProduct, deleteDevice, editDevice } = CalenderSlice.actions

export default CalenderSlice.reducer