import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as api from "../../api/ChatApi";

export const getAllChat = createAsyncThunk("/chat/getChat", async () => {
  const response = await api.getAllChat();
  console.log("response of Chat", response.data);
  return response.data.data;
});

export const chatSlice = createSlice({
  name: "chat",
  initialState: {
    isLoading: false,
    chat: [],
    chatId: null,
  },
  reducers: {
    setChatId: (state, action) => {
      console.log("setChatId =", action.payload);
      state.chatId = action.payload;
      console.log("Current chat object =", state.chat[state.chatId]);
    },
    updateCurrentChat: (state, action) => {
      const { orderId, userId } = action.payload;
      const filterIndex = state.chat.findIndex(
        (data) => data.orderId === orderId && data._id === userId
      );
      console.log("filterObjfilterObjfilterObj", filterIndex);
      console.log("filterObjfilterObjfilterObj", action.payload);

      if (filterIndex !== -1) {
        console.log("filterObjfilterObjfilterObj", state.chat[filterIndex]);
        state.chat[filterIndex].chats.push(action.payload);
      }
    },
    updateAllUserChat: (state, action) => {
      const { userId } = action.payload;
      const chatIndex = state.chat.findIndex((chat) => chat._id === userId);
      console.log("updateallchat", userId, action.payload);
      if (chatIndex !== -1) {
        state.chat[chatIndex].chats = action.payload;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllChat.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllChat.fulfilled, (state, action) => {
        console.log(`inside the ordersdata = ${action.payload}`);
        state.chat = action.payload;
        state.isLoading = false;
      })
      .addCase(getAllChat.rejected, (state) => {
        console.log(`inside the ordersdata rejected`);
        state.isLoading = false;
      });
  },
});

export const { setChatId, updateCurrentChat, updateAllUserChat } =
  chatSlice.actions;

export default chatSlice.reducer;
