import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'



export const getDestinationContent = createAsyncThunk('/destinations/contentss', async () => {
    const response = await axios.get('/dest/read')
    console.log("responsedevice",response);
    return response.data;
})

export const addNewCategory = createAsyncThunk("/destinations/contents", async (obj) => {
    const response = await axios.post('/dest/create', obj)
    console.log("responsenew", response.data);
    return response.data;
})

export const updateCategory = createAsyncThunk("/destinations/contents", async (obj) => {
    console.log("obg",obj);
    const response = await axios.put(`/dest/update/${obj.data._id}`, obj.data)
    return response.data;
})
export const deletedCategory = createAsyncThunk("/destinations/contents", async (_id) => {
    // console.log("obg",obj);
    const response = await axios.delete(`/dest/remove/${_id}`)
    return response.data;
})

export const destinationSlice = createSlice({
    name: 'destinations',
    initialState: {
        isLoading: false,
        destinations: [{
            name:"Pakistan"
        },
        {
            name:"India"
        }]
    },
    reducers: {
        addNewLead: (state, action) => {
            let { newLeadObj } = action.payload
            state.destinations = [...state.destinations, newLeadObj]
        },

        // deleteLead: (state, action) => {
        //     let { index } = action.payload
        //     state.leads.splice(index, 1)
        // },
        editLead: (state, action) => {
            const { index, updatedLead } = action.payload;
            state.destinations[index] = updatedLead;
        },
        // updateLead: (state, action) => {
        //     const { index, data } = action.payload;
        //     state.leads[index] = { ...state.leads[index], ...data };
        // },

    },

    extraReducers: {
        [getDestinationContent.pending]: state => {
            state.isLoading = true
        },
        [getDestinationContent.fulfilled]: (state, action) => {
            state.destinations = action.payload
            state.isLoading = false
        },
        [getDestinationContent.rejected]: state => {
            state.isLoading = false
        },
        [addNewCategory.pending]: state => {
            state.isLoading = true
        },
        [addNewCategory.fulfilled]: (state, action) => {
            state.destinations = action.payload
            state.isLoading = false
        },
        [addNewCategory.rejected]: state => {
            state.isLoading = false
        },
        [updateCategory.pending]: state => {
            state.isLoading = true
        },
        [updateCategory.fulfilled]: (state, action) => {
            window.location.href = '/app/transactions'
            // state.leads = action.payload
            state.isLoading = false
        },
        [updateCategory.rejected]: state => {
            state.isLoading = false
        },
        [deletedCategory.pending]: state => {
            state.isLoading = true
        },
        [deletedCategory.fulfilled]: (state, action) => {

            window.location.href = '/app/transactions'
            // state.leads = action.payload
            state.isLoading = false
        },
        [deletedCategory.rejected]: state => {
            state.isLoading = false
        },

    }
})

export const { addNewLead, deleteLead, editLead } = destinationSlice.actions

export default destinationSlice.reducer