import axios from "axios";

const URL = process.env.REACT_APP_BASE_URL;
// const URL = "http://192.168.18.143:5000/api";

const getToken = () => localStorage.getItem("token");

const getHeaders = () => {
  const token = getToken();
  return {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };
};

export const getAllChat = () => {
  console.log(
    `api chat = ${URL}/adminChat/`
  );
  return axios.get(
    `${URL}/adminChat/`,
    {
      headers: getHeaders(),
    }
  );
};
