import axios from "axios";

const URL = process.env.REACT_APP_BASE_URL; 

const getToken = () => localStorage.getItem("token");

const getHeaders = () => {
  const token = getToken();
  return {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };
};

export const getAllPayments = async (
    {
//   itemPerPage,
//   currentPage,
  searchText,
//   filter,
}
) => {
    console.log("api called");
  return await axios.get(`${URL}/paymentMethod?search=${searchText}`, {
    headers: getHeaders(),
  });
};

export const addPaymentData = ({ name, location }) => {
    const body = { name, location };
    console.log(
      `API payment added: userId = status = ${name}, reason = ${location}`
    );
    return axios.post(`${URL}/paymentMethod`, body, {
      headers: getHeaders(),
    });
  };

  export const updatePayment = ( {_id,name,location} ) => {
    const body = {_id,name,location}
    console.log(
      `API payment deleted: userId = ${_id}`
    );
    return axios.put(`${URL}/paymentMethod/${_id}`, body,{
      headers: getHeaders(),
    });
  };

  export const deletePayment = ( _id ) => {
    console.log(
      `API payment deleted: userId = ${_id}`
    );
    return axios.delete(`${URL}/paymentMethod/${_id}`, {
      headers: getHeaders(),
    });
  };