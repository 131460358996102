import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import * as api from "../../api/AdsApi";

export const getAdsContent = createAsyncThunk(
  "/adsSlice/content",
  async ({ itemPerPage, currentPage, searchText, orderType,
    paymentMethod,
    currency,
    minAmount,
    maxAmount,
    adsStatus,
    },{ rejectWithValue }) => {
    try {
      const response = await api.getAllAds({
        itemPerPage,
        currentPage,
        searchText,
        orderType,
        paymentMethod,
        currency,
        minAmount,
        maxAmount,
        adsStatus,
      });
      const users = response.data; 
      console.log("ads in Api = ",response);
      return users;
    } catch (error) {
      console.error("errorerror", error);
      return rejectWithValue(error.message || "Something went wrong");
    }
  }
);





export const updateAdsStatus = createAsyncThunk(
  "/category/updateUserStatus",
  async (body) => {
    
  }
);

export const adsSlice = createSlice({
  name: "adsSlice",
  initialState: {
    isLoading: false,
    totalpages: 0,
    ads: [],
  },
  reducers: {
    addNewLead: (state, action) => {
      let { newLeadObj } = action.payload;
      state.ads = [...state.ads, newLeadObj];
    },

    // deleteLead: (state, action) => {
    //     let { index } = action.payload
    //     state.ads.splice(index, 1)
    // },
    editLead: (state, action) => {
      const { index, updatedLead } = action.payload;
      state.ads[index] = updatedLead;
    },
    // updateLead: (state, action) => {
    //     const { index, data } = action.payload;
    //     state.ads[index] = { ...state.ads[index], ...data };
    // },
  },

  extraReducers: {
    [getAdsContent.pending]: (state) => {
      console.log("inpending");
      state.isLoading = true;
    },
    [getAdsContent.fulfilled]: (state, action) => {
      console.log(`ads payload = ${action.payload.meta.totalPages}`);
      state.ads = action.payload.data;
      state.totalpages = action.payload.meta.totalPages;
      state.isLoading = false;
    },
    [getAdsContent.rejected]: (state) => {
      state.isLoading = false;
      state.ads = []
    },
    [updateAdsStatus.fulfilled]: (state) => {
      state.isLoading = false;
    },
  },
});

export const { addNewLead, deleteLead, editLead } = adsSlice.actions;

export default adsSlice.reducer;