// import React, { lazy, useEffect, useState } from "react";
// import "./App.css";
// import {
//   BrowserRouter as Router,
//   Route,
//   Routes,
//   Navigate,
// } from "react-router-dom";
// import { themeChange } from "theme-change";
// import checkAuth from "./app/auth";
// import initializeApp from "./app/init";
// import socket from "./utils/socket/socket";
// import { useSelector } from "react-redux";
// import { decode } from "jsonwebtoken";
// import {jwtDecode} from "jwt-decode";
// // Importing pages
// const Layout = lazy(() => import("./containers/Layout"));
// const Login = lazy(() => import("./pages/Login"));
// const ForgotPassword = lazy(() => import("./pages/ForgotPassword"));
// const Register = lazy(() => import("./pages/Register"));
// const Documentation = lazy(() => import("./pages/Documentation"));

// // Initializing different libraries
// initializeApp();

// function App() {
//   const [token, setToken] = useState(null);
//   console.log("tokentoken", !!token);
//   const user = useSelector((state) => state.user.user);
//   socket.connect();
//   useEffect(() => {
//     const fetchAuth = async () => {
//       try {
//         const token = await checkAuth();
//         //socket
//         setToken(token);
//         console.log("tokentoken", !!token);
//         // socket.emit("user-connected", { userId: user._id })
//       } catch (error) {
//         console.error("error connecting", error);
//       }
//     };
//     themeChange(false);
//     fetchAuth();
//     return () => {
//       if (user) {
//         socket.emit("user-disconnected", { userId: user._id });
//       }
//     };
//   }, [user]);

//   console.log("cz", localStorage.getItem("token"));
//   if (!!localStorage.getItem("token")) {
//     const decodedToken = jwtDecode(localStorage.getItem("token"));
//     console.log("yes it is called,",decodedToken.userId);
//     socket.emit("user-connected", { userId: decodedToken.userId });
//   }

//   return (
//     <Router>
//       <Routes>
//         <Route path="/login" element={<Login />} />
//         <Route path="/forgot-password" element={<ForgotPassword />} />
//         <Route path="/register" element={<Register />} />
//         <Route path="/documentation" element={<Documentation />} />
//         <Route path="/app/*" element={<Layout />} />
//         <Route
//           path="*"
//           element={<Navigate to={token ? "/app/welcome" : "/login"} replace />}
//         />
//       </Routes>
//     </Router>
//   );
// }

// export default App;

import React, { lazy, useEffect, useState } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { themeChange } from "theme-change";
import checkAuth from "./app/auth";
import initializeApp from "./app/init";
import socket from "./utils/socket/socket";
import { useSelector } from "react-redux";
import { jwtDecode } from "jwt-decode"; // Correct import for jwt-decode

// Importing pages
const Layout = lazy(() => import("./containers/Layout"));
const Login = lazy(() => import("./pages/Login"));
const ForgotPassword = lazy(() => import("./pages/ForgotPassword"));
const Register = lazy(() => import("./pages/Register"));
const Documentation = lazy(() => import("./pages/Documentation"));

// Initializing different libraries
initializeApp();

function App() {
  const [token, setToken] = useState(null);
  console.log("tokentoken", !!token);
  const user = useSelector((state) => state.user.user);
  socket.connect();

  useEffect(() => {
    const fetchAuth = async () => {
      try {
        const token = await checkAuth();
        //socket
        setToken(token);
        console.log("tokentoken", !!token);
        // socket.emit("user-connected", { userId: user._id })
      } catch (error) {
        console.error("error connecting", error);
      }
    };
    themeChange(false);
    fetchAuth();
    return () => {
      if (user) {
        socket.emit("user-disconnected", { userId: user._id });
      }
    };
  }, [user]);

  useEffect(() => {
    const storedToken = localStorage.getItem("token");
    if (storedToken) {
      try {
        const decodedToken = jwtDecode(storedToken);
        console.log("decode", decodedToken.id);
        console.log(
          "yes it is called,",
          decodedToken.userId,
          storedToken,
          user
        );
        socket.emit("user-connected", { userId: decodedToken.id });
      } catch (error) {
        console.error("Failed to decode token:", error);
      }
    }
  }, [user]);

  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/register" element={<Register />} />
        <Route path="/documentation" element={<Documentation />} />
        <Route path="/app/*" element={<Layout />} />
        <Route
          path="*"
          element={<Navigate to={token ? "/app/welcome" : "/login"} replace />}
        />
      </Routes>
    </Router>
  );
}

export default App;
