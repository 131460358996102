import axios from "axios";

const URL = process.env.REACT_APP_BASE_URL;
const getToken = () => localStorage.getItem("token");

const getHeaders = () => {
  const token = getToken();
  return {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };
};

export const getAllUsers = ({
  itemPerPage,
  currentPage,
  searchText,
  kycFilter,
  userStatus,
  kycStatus,
}) => {
  console.log(
    `api data = ${URL}/user?limit=${itemPerPage}&page=${currentPage}&email=${searchText}&filterKyc=${kycFilter}&kycStatus=${kycStatus}&userStatus=${userStatus}/${getToken()}`
  );
  return axios.get(
    `${URL}/user?limit=${itemPerPage}&page=${currentPage}&email=${searchText}&filterKyc=${kycFilter}&kycStatus=${kycStatus}&userStatus=${userStatus}`,
    {
      headers: getHeaders(),
    }
  );
};



export const updateKycStatus = ({ userId, status, reason }) => {
  const body = { status, reason };
  console.log(
    `API update: userId = ${userId}, status = ${status}, reason = ${reason}`
  );
  return axios.post(`${URL}/user/verifykyc/${userId}`, body, {
    headers: getHeaders(),
  });
};

export const updateUserStatus = ({ _id, status }) => {
  const body = { status };
  console.log(`API update: userId = ${_id}, status = ${status}`);
  return axios.put(`${URL}/user/updateUserStatus/${_id}`, body, {
    headers: getHeaders(),
  });
};