import axios from "axios";
const checkAuth = async () => {
  const TOKEN = localStorage.getItem("token");
  const PUBLIC_ROUTES = ["login", "forgot-password", "register", "documentation"];
  const isPublicPage = PUBLIC_ROUTES.some((route) => window.location.href.includes(route));
  try {
    const response = await axios.get("/auth/verifyAccessToken", {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${TOKEN}`,
        
      },
    });
    if (isPublicPage && response.status === 201) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${TOKEN}`;
      window.location.href = "/app/welcome "
      return TOKEN;
    } else {
      throw new Error("Token is invalid");
    }
  } catch (error) {
    if (!error.response.status === 401) {
      localStorage.removeItem("token");
      alert("Token is invalid");
      window.location.href = "/login";
    }
    return null;
  }
};
export default checkAuth;
