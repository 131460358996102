import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'



export const getDeviceContent = createAsyncThunk('/devices/contents', async () => {
    const response = await axios.get('/device/read')
    console.log("resposnedevice",response);
    return response.data;
})

export const addNewDevices = createAsyncThunk("/devices/content", async (obj) => {
    // console.log("callling");
    const response = await axios.post('/device/create', obj)
    console.log("responsenewadd", response.data);
    return response.data;
})

export const updateDevice = createAsyncThunk("/devices/content", async (obj) => {
    console.log("obg",obj);
    const response = await axios.put(`/device/update/${obj.data._id}`, obj.data)
    return response.data;
})
export const deletedDevice = createAsyncThunk("/devices/content", async (_id) => {
    // console.log("_iddsadasdas",_id);
    const response = await axios.delete(`/device/remove/${_id}`)
    // console.log("deletetee",response)
    return response.data;
})

export const deviceSlice = createSlice({
    name: 'devices',
    initialState: {
        isLoading: false,
        devices: ["Pakistan","India"]
    },
    reducers: {
        addNewDevice: (state, action) => {
            let { newLeadObj } = action.payload
            state.devices = [...state.devices, newLeadObj]
        },

        // deleteLead: (state, action) => {
        //     let { index } = action.payload
        //     state.leads.splice(index, 1)
        // },
        editDevice: (state, action) => {
            const { index, updatedLead } = action.payload;
            state.devices[index] = updatedLead;
        },
        // updateLead: (state, action) => {
        //     const { index, data } = action.payload;
        //     state.leads[index] = { ...state.leads[index], ...data };
        // },

    },

    extraReducers: {
        [getDeviceContent.pending]: state => {
            state.isLoading = true
        },
        [getDeviceContent.fulfilled]: (state, action) => {
            state.devices = action.payload
            state.isLoading = false
        },
        [getDeviceContent.rejected]: state => {
            state.isLoading = false
        },
        [addNewDevices.pending]: state => {
            state.isLoading = true
        },
        [addNewDevices.fulfilled]: (state, action) => {
            state.devices = action.payload
            state.isLoading = false
        },
        [addNewDevices.rejected]: state => {
            state.isLoading = false
        },
        [updateDevice.pending]: state => {
            state.isLoading = true
        },
        [updateDevice.fulfilled]: (state, action) => {
            window.location.href = '/app/integration'
            // state.leads = action.payload
            state.isLoading = false
        },
        [updateDevice.rejected]: state => {
            state.isLoading = false
        },
        [deletedDevice.pending]: state => {
            state.isLoading = true
        },
        [deletedDevice.fulfilled]: (state, action) => {
            window.location.href = '/app/integration'
            // state.leads = action.payload
            state.isLoading = false
        },
        [deletedDevice.rejected]: state => {
            state.isLoading = false
        },

    }
})

export const { addNewDevice, deleteDevice, editDevice } = deviceSlice.actions

export default deviceSlice.reducer