import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import * as api from "../../api/settingsApi";

export const getSettings = createAsyncThunk(
  "/configSlice/getSettings",
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.getSettings();
      const setting = response.data.data;
      console.log("settings in Api = ", setting);
      return setting;
    } catch (error) {
      console.error("errorerror", error);
      return rejectWithValue(error.message || "Something went wrong");
    }
  }
);
export const updateSettings = createAsyncThunk(
    "/configSlice/updateSettings",
    async (form, { rejectWithValue }) => {
      try {
        const {
            minAdDeposit,
            maxAdDeposit,
            minDepositAmount,
            restrictDeposit,
            restrictWithDraw,
          } = form
        const response = await api.updateSettings({
            minAdDeposit,
            maxAdDeposit,
            minDepositAmount,
            restrictDeposit,
            restrictWithDraw,
          });
        const setting = response.data.data;
        console.log("settings in Api = ", setting);
        return setting;
      } catch (error) {
        console.error("errorerror", error);
        return rejectWithValue(error.message || "Something went wrong");
      }
    }
  );

export const configSlice = createSlice({
  name: "configSlice",
  initialState: {
    isLoading: false,
    setting: {},
  },
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(getSettings.pending, (state) => {})
      .addCase(getSettings.fulfilled, (state, action) => {
        console.log(`settings in payload ${action.payload}`);
        state.setting = action.payload;
      })
      .addCase(getSettings.rejected, (state) => {
        console.log("rejected");
      })
      .addCase(updateSettings.pending, (state) => {})
      .addCase(updateSettings.fulfilled, (state, action) => {
        console.log(`settings in payload ${action.payload}`);
        state.setting = action.payload;
      })
      .addCase(updateSettings.rejected, (state) => {
        console.log("rejected");
      });
  },
});

export const {} = configSlice.actions;

export default configSlice.reducer;
