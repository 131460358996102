import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import * as api from "../../api/AppealOrderApi"



export const getAllAppealOrders = createAsyncThunk('/products/contents', async ({itemPerPage,currentPage}) => {
    const response = await api.getAllAppealOrders({itemPerPage,currentPage})
    console.log("response of appeal ",response.data.data);
    return response.data.data;
})
export const postReleaseBuyer = createAsyncThunk('/products/releaseBuyer', async (body) => {
    console.log("body in buyer",body.orderId);
    const response = await api.postReleaseToBuyer(body)
    console.log("response of appeal buyer",response);
    return response.data.data;
})
export const postReleaseSeller = createAsyncThunk('/products/releaseSeller', async (body) => {
    console.log("body in seller",body.orderId);
    const response = await api.postReleaseToSeller(body)
    console.log("response of appeal seller",response);
    return response.data.data;
})
export const postThrowNotification = createAsyncThunk('/products/throwNotification', async (body) => {
    console.log("body in throw",body.orderId);
    const response = await api.postThrowToNotification(body)
    console.log("response of appeal throw",response);
    return response.data.data;
})
// export const addNewProducts = createAsyncThunk("/products/content", async (obj) => {
//     // console.log("callling");
//     const response = await axios.post('/product/create', obj)
//     console.log("responsenewadd create", response.data);
//     return response.data;
// })

// export const updateProduct = createAsyncThunk("/products/content", async (obj) => {
//     console.log("obg",obj);
//     const response = await axios.put(`/product/update/${obj.data._id}`, obj.data)
//     return response.data;
// })
// export const deletedProduct = createAsyncThunk("/products/content", async (_id) => {
//     // console.log("_iddsadasdas",_id);
//     const response = await axios.delete(`/product/remove/${_id}`)
//     // console.log("deletetee",response)
//     return response.data;
// })

export const appealOrderSlice = createSlice({
    name: 'appealOrder',
    initialState: {
        isLoading: false,
        appealOrder: []
    },
    reducers: {
        addNewProduct: (state, action) => {
            let { newLeadObj } = action.payload
            state.products = [...state.products, newLeadObj]
        },
        editDevice: (state, action) => {
            const { index, updatedLead } = action.payload;
            state.products[index] = updatedLead;
        },
    },

    extraReducers: {
        [getAllAppealOrders.pending]: state => {
            state.isLoading = true
        },
        [getAllAppealOrders.fulfilled]: (state, action) => {
            console.log(`inside the ordersdata = ${action.payload.totalPages}`);
           state.appealOrder = action.payload
            state.isLoading = false
        },
        [getAllAppealOrders.rejected]: state => {
            console.log(`inside the ordersdata = `);
            state.isLoading = false
        },
        //
        [postReleaseBuyer.pending]: state => {
            state.isLoading = true
        },
        [postReleaseBuyer.fulfilled]: (state, action) => {
            console.log(`inside the ordersdata = ${action.payload.totalPages}`);
           state.appealOrder = action.payload
            state.isLoading = false
        },
        [postReleaseBuyer.rejected]: state => {
            console.log(`inside the ordersdata = `);
            state.isLoading = false
        },
        //
        [postReleaseSeller.pending]: state => {
            state.isLoading = true
        },
        [postReleaseSeller.fulfilled]: (state, action) => {
            console.log(`inside the ordersdata seller = ${action.payload}`);
            state.appealOrder = action.payload
            state.isLoading = false
        },
        [postReleaseSeller.rejected]: state => {
            console.log(`inside the ordersdata reject= `);
            state.isLoading = false
        },
    }
})

export const { addNewProduct, deleteDevice, editDevice } = appealOrderSlice.actions

export default appealOrderSlice.reducer