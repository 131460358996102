import axios from "axios";

const URL = process.env.REACT_APP_BASE_URL; 

const getToken = () => localStorage.getItem("token");

const getHeaders = () => {
  const token = getToken();
  return {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };
};

export const getSettings = async () => {
    console.log("api called");
  return await axios.get(`${URL}/random/getAmount`, {
    headers: getHeaders(),
  });
};

export const updateSettings = ({
    minAdDeposit,
    maxAdDeposit,
    minDepositAmount,
    restrictDeposit,
    restrictWithDraw,
  }) => {
    const body = {
        minAdDeposit,
        maxAdDeposit,
        minDepositAmount,
        restrictDeposit,
        restrictWithDraw,
      };
    console.log(
      `API payment added: userId = status = ${
        minAdDeposit,
        maxAdDeposit,
        minDepositAmount,
        restrictDeposit,
        restrictWithDraw}`
    );
    return axios.post(`${URL}/random/updateMinDeposit`, body, {
      headers: getHeaders(),
    });
  };