import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'


// export const addNewEvents = createAsyncThunk(
//     'events/addNewEvent',
//     async ({ selectedEventId, imageData }) => {
//         console.log("imageData", imageData);
//         // const response = await axios.post(`/event/uploadImages/${eventId}`, formData, 
//         // // responseType: 'blob',
//         // {
//         //         headers: { 'Content-Type': 'multipart/form-data' },
//         //     });
//         const response = axios.post(`/event/uploadImages/${selectedEventId}`, { imageData });
//         return response.data;
//     }
// );

export const addNewEvents = createAsyncThunk(
    'eventmain/addNewEvent',
    async ({ selectedEventId, imageData }) => {
      console.log("imageData", imageData);
      try {
        const response = await axios.post(`/event/uploadImages/${selectedEventId}`, {imageData}, {
        });
        return response.data;
      } catch (error) {
        console.error('Error uploading images:', error);
        throw error; 
      }
    }
  );


  export const getEventsContent = createAsyncThunk('/eventmain/content', async (_id) => {
    console.log("eventId _id",_id);
    try {
      const response = await axios.get(`/event/getEventImages?eventId=${_id}`);
      console.log("response event read", response);
      return response.data;
    } catch (error) {
      console.error('Error fetching events:', error);
      throw error;
    }
  });
export const getEventEditContent = createAsyncThunk('/event/content', async () => {
    const response = await axios.get(`/event/read`);
    console.log("response from read",response);
    return response.data;
  });
  
// export const getEventEditContent = createAsyncThunk(
//     '/banner/content',
//     async (id) => {
//         const response = await axios.get(`/event/read/${id}`);
//         return response.data;
//     }
// );
export const updateEvent = createAsyncThunk("banner/updateBanner", async (obj) => {
    console.log("bannerData", obj);
    const response = await axios.put(`/banner/update/${obj.id}`, obj.formData);
    console.log("respose updatebanner", response);
    return response.data;

});


export const deletedEvent = createAsyncThunk("/event/content", async ({ eventId, imageId }) => {
    console.log("event and image ids", eventId, imageId);
    const response = await axios.delete(`/event/deleteImage/${imageId}`)
    console.log("deletetee banner", response)
    return response.data;
})


export const eventsSlice = createSlice({
    name: 'eventMain',
    initialState: {
        isLoading: false,
        eventMain: []
    },
    reducers: {
        addNewBanner: (state, action) => {
            let { newLeadObj } = action.payload
            state.eventMain = [...state.eventMain, newLeadObj]
        },

        // deleteLead: (state, action) => {
        //     const { index } = action.payload;
        //     state.banners.splice(index, 1);
        //   },          
        editLead: (state, action) => {
            const { index, updatedLead } = action.payload;
            state.eventMain[index] = updatedLead;
        },
        updateLead: (state, action) => {
            const { index, data } = action.payload;
            state.eventMain[index] = { ...state.eventMain[index], ...data };
        },
    },

    extraReducers: {
        [getEventsContent.pending]: state => {
            state.isLoading = true
        },
        [getEventsContent.fulfilled]: (state, action) => {
            state.eventMain = action.payload
            state.isLoading = false
        },
        [getEventsContent.rejected]: state => {
            state.isLoading = false
        },
        [addNewEvents.pending]: state => {
            state.isLoading = true
        },
        [addNewEvents.fulfilled]: (state, action) => {
            state.eventMain = action.payload.data
            window.location.href = '/app/settings-team  '
            state.isLoading = false
        },
        [addNewEvents.rejected]: state => {
            state.isLoading = false
        },
        [deletedEvent.pending]: state => {
            state.isLoading = true
        },
        [deletedEvent.fulfilled]: (state, action) => {
            window.location.href = '/app/settings-team'
            // state.leads = action.payload
            state.isLoading = false
        },
        [deletedEvent.rejected]: state => {
            state.isLoading = false
        },
        [getEventEditContent.pending]: state => {
            state.isLoading = true
        },
        [getEventEditContent.fulfilled]: (state, action) => {
            // window.location.href = '/app/charts/:id'
            // state.leads = action.payload
            state.isLoading = false
        },
        [getEventEditContent.rejected]: state => {
            state.isLoading = false
        },
        [updateEvent.pending]: state => {
            state.isLoading = true
        },
        [updateEvent.fulfilled]: (state, action) => {
            window.location.href = '/app/charts'
            // state.leads = action.payload
            state.isLoading = false
        },
        // [updateBanner.fulfilled]: (state, action) => {
        //     // Update the banner in the state
        //     const index = state.banners.findIndex((banner) => banner._id === action.payload._id);
        //     if (index !== -1) {
        //         state.banners[index] = action.payload;
        //     }
        // },
        [updateEvent.rejected]: state => {
            state.isLoading = false
        },
    }
})

export const { addNewEvent, deleteLead, editLead, updateLead } = eventsSlice.actions

export default eventsSlice.reducer