import { createSlice,createAsyncThunk } from '@reduxjs/toolkit'
import * as api from "../../api/notiificationApi"

export const getAllNotification = createAsyncThunk(
    "header/getNotificatoion",
    async () => {
        console.log("notifications called");
      const response = await api.getAllNotification();
      console.log("response user",response);
      const user = response.data.data;
      return user;
    }
  );



export const headerSlice = createSlice({
    name: 'header',
    initialState: {
        pageTitle: "Home",  // current page title state management
        noOfNotifications : 0,  // no of unread notifications
        notifications : [],  // notifications list
        newNotificationMessage : "",  // message of notification to be shown
        newNotificationStatus : 1,   // to check the notification type -  success/ error/ info
    },
    reducers: {
        setPageTitle: (state, action) => {
            state.pageTitle = action.payload.title
        },

        setNoOfNotifications: (state, action) => {
            state.noOfNotifications = action.payload.totalNotifications;
            // state.notifications = action.payload.notifications;
        },

        removeNotificationMessage: (state, action) => {
            state.newNotificationMessage = action.payload
        },

        showNotification: (state, action) => {
            state.newNotificationMessage = action.payload.message
            state.newNotificationStatus = action.payload.status
        },
    },
    extraReducers: (builder) => {
        builder
          .addCase(getAllNotification.pending, (state) => {
            state.isLoading = true;
          })
          .addCase(getAllNotification.fulfilled, (state, action) => {
            state.notifications = action.payload.length;
            state.isLoading = false;
          })
          .addCase(getAllNotification.rejected, (state) => {
            state.isLoading = false;
          })
        }
})

export const { setPageTitle, removeNotificationMessage, showNotification, setNoOfNotifications } = headerSlice.actions

export default headerSlice.reducer