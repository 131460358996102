import axios from "axios";

const URL = process.env.REACT_APP_BASE_URL; 



const getToken = () => localStorage.getItem("token");

const getHeaders = () => {
  const token = getToken();
  return {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };
};

export const getAllAds = async ({
  itemPerPage,
  currentPage,
  searchText,
  orderType,
  paymentMethod,
  currency,
  minAmount,
  maxAmount,
  adsStatus,
}) => {
  console.log(`url in ads here is  = ${URL}/ads/getAdds?limit=${itemPerPage}&page=${currentPage}`);
  // /ads/getAdds?limit=4&page=1&orderType=buy&paymentMethod=Mezzan&currency=pkr&minAmount=100&maxAmount=10000
  return await axios.get(`${URL}/ads/getAdds?limit=${itemPerPage}&page=${currentPage}&orderType=${orderType}&paymentMethod=${paymentMethod}&currency=${currency}&minAmount=${minAmount}&maxAmount=${maxAmount}&nickName=${searchText}&status=${adsStatus}`, {
    headers: getHeaders(),
  });
};