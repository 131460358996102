import { configureStore } from "@reduxjs/toolkit";
import headerSlice from "../features/common/headerSlice";
import modalSlice from "../features/common/modalSlice";
import rightDrawerSlice from "../features/common/rightDrawerSlice";
import leadsSlice from "../features/leads/userSlice";
import adsSlice from "../features/ads/adsSlice";
import destinationSlice from "../features/transactions/destinantionSlice";
import deviceSlice from "../features/integration/deviceSlice";
import CalenderSlice from "../features/calendar/productSlice";
import bankSlice from "../features/charts/bankSlice";
import eventSlice from "../features/settings/billing/eventSlice";
import eventsSlice from "../features/settings/team/eventSliceMain";
import configSlice from "../features/Configurations/configSlice";
import appealOrderSlice from "../features/appealorders/appealOrderSlice";
import chatSlice from "../features/chat/chatSlice";
import userSlice from "../features/user/userSlice";


const combinedReducer = {
  header: headerSlice,
  rightDrawer: rightDrawerSlice,
  modal: modalSlice,
  lead: leadsSlice,
  ads: adsSlice,
  destination: destinationSlice,
  device: deviceSlice,
  product: CalenderSlice,
  bank: bankSlice,
  event: eventSlice,
  eventMain: eventsSlice,
  setting:configSlice,
  appealOrder:appealOrderSlice,
  chat:chatSlice,
  user:userSlice,
};

export default configureStore({
  reducer: combinedReducer,
});
