import axios from "axios";

const URL = process.env.REACT_APP_BASE_URL;

const getToken = () => localStorage.getItem("token");

const getHeaders = () => {
  const token = getToken();
  return {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };
};

export const getAllOrders = ({itemPerPage,currentPage,searchText, orderStatus, orderType }) =>
  {
    console.log(`api data = ${URL}/order?status=`);
    return axios.get(`${URL}/order?limit=${itemPerPage}&page=${currentPage}&orderId=${searchText}&status=${orderStatus}&orderType=${orderType}`, {
      headers: getHeaders(),
    });
  };
