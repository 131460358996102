import { createSlice } from '@reduxjs/toolkit';


const userSlice = createSlice({
  name: 'user',
  initialState: {
    user: {},
},
  reducers: {
    setUser: (state, action) => {
      console.log("user set = ",action.payload,state.user);
      state.user = action.payload;
      console.log("user set after = ",state.user);
    },
  },
});

export const { setUser } = userSlice.actions;
export default userSlice.reducer;
