import axios from "axios";

const URL = process.env.REACT_APP_BASE_URL;
// const URL = "http://192.168.18.143:5000/api";

const getToken = () => localStorage.getItem("token");

const getHeaders = () => {
  const token = getToken();
  return {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };
};

export const getAllAppealOrders = ({ itemPerPage, currentPage }) => {
  console.log(
    `api data = ${URL}/order/getAppealedOrder?limit=${itemPerPage}&page=${currentPage}`
  );
  return axios.get(
    `${URL}/order/getAppealedOrder?limit=${itemPerPage ?? 10}&page=${
      currentPage ?? 1
    }`,
    {
      headers: getHeaders(),
    }
  );
};

export const postReleaseToBuyer = ({ orderId}) => {
    const body = { orderId}
    console.log(
      `api data = ${URL}/order/releaseToBuyer,${body}`
    );
    
    return axios.post(
      `${URL}/order/releaseToBuyer`,body,
      {
        headers: getHeaders(),
      }
    );
  };

  export const postReleaseToSeller = ({ orderId}) => {
    
    const body = { orderId}
    console.log(
        `api data = ${URL}/order/releaseToSeller,${body.orderId}`
      );
    return axios.post(
      `${URL}/order/releaseToSeller`,body,
      {
        headers: getHeaders(),
      }
    );
};

export const postThrowToNotification = ({ orderId}) => {
    
    const body = { orderId}
    console.log(
        `api data = ${URL}/order/throwNotification,${body.orderId}`
      );
    return axios.post(
      `${URL}/order/throwNotification`,body,
      {
        headers: getHeaders(),
      }
    );
};
